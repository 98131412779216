import { INode } from '@mrblenny/react-flow-chart';
import {
    Campaign,
    WorkflowConditionScheduleType,
    UserAttributeBackend,
    CampaignAttributes,
    WorkflowScheduleErrorType,
    WorkflowActionScheduleType,
} from 'types';
import { EMAIL_OPENED, CHECK_ATTRIBUTE, LIVE } from 'common/constants';
import { isEmailValid, isReplyToEmailValid, isSenderEmailValid } from 'utils/email';
import { checkAssetHasValidationErrors } from './campaignUtils';

const isValid = (value: any) => {
    return value !== null && typeof value !== 'undefined' && value !== '';
};

export const getActionTimeVariableError = (condition: INode, isInputFieldValidation = false) => {
    const actionNodeProperties = condition.properties;
    let actionNodeTimeVariableToBeValidated;

    switch (actionNodeProperties.scheduleType) {
        case WorkflowActionScheduleType.IMMEDIATELY:
            return WorkflowScheduleErrorType.NO_ERROR;
        case WorkflowActionScheduleType.WAIT:
            actionNodeTimeVariableToBeValidated = actionNodeProperties.daysToWait;
            break;
        case WorkflowActionScheduleType.SPECIFIC_DAY:
            actionNodeTimeVariableToBeValidated = actionNodeProperties.dayOfWeek;
            break;
        default:
            throw new Error('Invalid WorkflowActionScheduleType');
    }

    if (
        (!isValid(actionNodeTimeVariableToBeValidated) ||
            !isValid(actionNodeProperties.hours) ||
            !isValid(actionNodeProperties.minutes)) &&
        (!isInputFieldValidation || (isInputFieldValidation && !actionNodeProperties.isNew))
    ) {
        return WorkflowScheduleErrorType.EMPTY_VALUE;
    } else if (!Number.isInteger(Number(actionNodeTimeVariableToBeValidated))) {
        return WorkflowScheduleErrorType.INVALID_FORMAT;
    } else if (
        actionNodeProperties.scheduleType === WorkflowActionScheduleType.WAIT &&
        Number(actionNodeTimeVariableToBeValidated) < 1
    ) {
        return WorkflowScheduleErrorType.MIN_VALUE;
    } else {
        return WorkflowScheduleErrorType.NO_ERROR;
    }
};

export const isActionValid = (action: INode) => {
    return (
        isValid(action.properties.assetId) && getActionTimeVariableError(action) === WorkflowScheduleErrorType.NO_ERROR
    );
};

export const getConditionTimeVariableError = (condition: INode, isInputFieldValidation = false) => {
    const conditionNodeProperties = condition.properties;
    let conditionNodeTimeVariableToBeValidated;

    switch (conditionNodeProperties.scheduleType) {
        case WorkflowConditionScheduleType.IMMEDIATELY:
            return WorkflowScheduleErrorType.NO_ERROR;
        case WorkflowConditionScheduleType.WAIT:
            conditionNodeTimeVariableToBeValidated = conditionNodeProperties.daysToWait;
            break;
        case WorkflowConditionScheduleType.FOR_A_PERIOD_OF:
            conditionNodeTimeVariableToBeValidated = conditionNodeProperties.daysInPeriod;
            break;
        default:
            throw new Error('Invalid WorkflowConditionScheduleType');
    }

    if (
        !isValid(conditionNodeTimeVariableToBeValidated) &&
        (!isInputFieldValidation || (isInputFieldValidation && !conditionNodeProperties.isNew))
    ) {
        return WorkflowScheduleErrorType.EMPTY_VALUE;
    } else if (!Number.isInteger(Number(conditionNodeTimeVariableToBeValidated))) {
        return WorkflowScheduleErrorType.INVALID_FORMAT;
    } else if (Number(conditionNodeTimeVariableToBeValidated) < 1) {
        return WorkflowScheduleErrorType.MIN_VALUE;
    } else {
        return WorkflowScheduleErrorType.NO_ERROR;
    }
};

export const isConditionValid = (condition: INode, campaignAttributes?: CampaignAttributes) => {
    if (!condition.properties.scheduleType) {
        return false;
    }

    if (getConditionTimeVariableError(condition) !== WorkflowScheduleErrorType.NO_ERROR) {
        return false;
    }

    if (condition?.properties.subtype === EMAIL_OPENED && !condition.properties.assetId) {
        return false;
    }

    if (
        condition?.properties.subtype === CHECK_ATTRIBUTE &&
        (!condition.properties.attribute ||
            !condition.ports ||
            Object.keys(condition.ports).length <= 1 ||
            !isCampaignAttributeValid(condition, campaignAttributes))
    ) {
        return false;
    }

    return true;
};

export const isCampaignValid = (campaign: Campaign, mode: string, campaignAttributes?: CampaignAttributes) => {
    if (
        !campaign ||
        !campaign.senderEmail ||
        !isSenderEmailValid(campaign.senderEmail) ||
        (!!campaign.replyToEmail && !isReplyToEmailValid(campaign.replyToEmail!)) ||
        !campaign.name ||
        !(campaign.audiences && campaign.audiences[0] !== undefined && campaign.audiences[0].id !== '') ||
        !campaign.assets!.length ||
        campaign.assets!.some((asset) => checkAssetHasValidationErrors(asset))
    ) {
        return false;
    }
    const { nodes, links } = mode === LIVE ? campaign.workflow! : campaign.workflowDraft!;
    const q = [nodes.root];
    let hasAction = false;
    let count = 0;
    let last = nodes.root;
    while (q.length) {
        const cur = q.shift();
        count++;
        last = cur!;
        if (cur?.type === 'action') {
            hasAction = true;
            if (!isActionValid(cur)) {
                return false;
            }
        }
        if (cur?.type === 'condition' && !isConditionValid(cur, campaignAttributes)) {
            return false;
        }
        for (const key of Object.keys(links)) {
            if (links[key].from.nodeId === cur?.id) {
                q.push(nodes[links[key].to.nodeId!]);
            }
        }
    }
    return count === Object.keys(nodes).length && hasAction && last && last.type === 'action';
};

export const isCampaignAttributeValid = (node: INode, campaignAttributes?: CampaignAttributes) => {
    if (!campaignAttributes) return true;

    const audienceId = Object.keys(campaignAttributes)[0];
    const attributes = campaignAttributes[audienceId];
    if (!attributes) return true;

    let isAttributeExistsInAudience = true;
    const currentAttributeName = node.properties.attribute as string;
    const nodeAudienceId = node.properties.audienceId as string;

    if (nodeAudienceId !== audienceId) return false;

    if (currentAttributeName) {
        const selectedAttribute = attributes.find(
            (attribute: UserAttributeBackend) => attribute.name === currentAttributeName,
        );
        if (selectedAttribute && selectedAttribute.name !== undefined) {
            isAttributeExistsInAudience = attributes.some(
                (attribute: UserAttributeBackend) => attribute.name === selectedAttribute.name,
            );
        } else {
            isAttributeExistsInAudience = false;
        }
    }
    return isAttributeExistsInAudience;
};

export const isNewConnection = (newLinkFromNodeId: string, oldLinkFromNodeId: string, oldLinkToNodeId?: string) => {
    return oldLinkFromNodeId === newLinkFromNodeId && !oldLinkToNodeId;
};

export const isConnected = (
    newLinkFromNodeId: string,
    newLinkFromPortId: string,
    newLinkToNodeId: string,
    newLinkToPortId: string,
    oldLinkFromNodeId: string,
    oldLinkFromPortId: string,
    oldLinkToNodeId: string,
    oldLinkToPortId: string,
) => {
    return (
        (oldLinkFromNodeId === newLinkFromNodeId && oldLinkFromPortId === newLinkFromPortId) ||
        (oldLinkFromNodeId === newLinkToNodeId && oldLinkFromPortId === newLinkToPortId) ||
        (oldLinkToNodeId === newLinkFromNodeId && oldLinkToPortId === newLinkFromPortId) ||
        (oldLinkToNodeId === newLinkToNodeId && oldLinkToPortId === newLinkToPortId)
    );
};
